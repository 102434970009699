@import "https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap";
h1 {
  font-size: 42px !important;
}

h2 {
  font-size: 36px !important;
}

h3 {
  font-size: 30px !important;
}

h4 {
  font-size: 24px !important;
}

h5 {
  font-size: 20px !important;
}

h6 {
  font-size: 16px !important;
}

.display-1 {
  font-size: 80px !important;
}

.display-2 {
  font-size: 72px !important;
}

.display-3 {
  font-size: 64px !important;
}

.display-4 {
  font-size: 56px !important;
}

.bg-primary {
  background-color: #2f55d4 !important;
}

.bg-soft-primary {
  color: #2f55d4 !important;
  background-color: rgba(47, 85, 212, .1) !important;
  border: 1px solid rgba(47, 85, 212, .1) !important;
}

.text-primary {
  color: #2f55d4 !important;
}

a {
  transition: all .5s;
}

a.text-primary:hover, a.text-primary:focus {
  color: #284bc1 !important;
}

.bg-secondary {
  background-color: #5a6d90 !important;
}

.bg-soft-secondary {
  color: #5a6d90 !important;
  background-color: rgba(90, 109, 144, .1) !important;
  border: 1px solid rgba(90, 109, 144, .1) !important;
}

.text-secondary {
  color: #5a6d90 !important;
}

a {
  transition: all .5s;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #506180 !important;
}

.bg-success {
  background-color: #2eca8b !important;
}

.bg-soft-success {
  color: #2eca8b !important;
  background-color: rgba(46, 202, 139, .1) !important;
  border: 1px solid rgba(46, 202, 139, .1) !important;
}

.text-success {
  color: #2eca8b !important;
}

a {
  transition: all .5s;
}

a.text-success:hover, a.text-success:focus {
  color: #29b57d !important;
}

.bg-warning {
  background-color: #f17425 !important;
}

.bg-soft-warning {
  color: #f17425 !important;
  background-color: rgba(241, 116, 37, .1) !important;
  border: 1px solid rgba(241, 116, 37, .1) !important;
}

.text-warning {
  color: #f17425 !important;
}

a {
  transition: all .5s;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ed650f !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-soft-info {
  color: #17a2b8 !important;
  background-color: rgba(23, 162, 184, .1) !important;
  border: 1px solid rgba(23, 162, 184, .1) !important;
}

.text-info {
  color: #17a2b8 !important;
}

a {
  transition: all .5s;
}

a.text-info:hover, a.text-info:focus {
  color: #148ea1 !important;
}

.bg-danger {
  background-color: #e43f52 !important;
}

.bg-soft-danger {
  color: #e43f52 !important;
  background-color: rgba(228, 63, 82, .1) !important;
  border: 1px solid rgba(228, 63, 82, .1) !important;
}

.text-danger {
  color: #e43f52 !important;
}

a {
  transition: all .5s;
}

a.text-danger:hover, a.text-danger:focus {
  color: #e1293e !important;
}

.bg-dark {
  background-color: #3c4858 !important;
}

.bg-soft-dark {
  color: #3c4858 !important;
  background-color: rgba(60, 72, 88, .1) !important;
  border: 1px solid rgba(60, 72, 88, .1) !important;
}

.text-dark {
  color: #3c4858 !important;
}

a {
  transition: all .5s;
}

a.text-dark:hover, a.text-dark:focus {
  color: #323c49 !important;
}

.bg-muted {
  background-color: #8492a6 !important;
}

.bg-soft-muted {
  color: #8492a6 !important;
  background-color: rgba(132, 146, 166, .1) !important;
  border: 1px solid rgba(132, 146, 166, .1) !important;
}

.text-muted {
  color: #8492a6 !important;
}

a {
  transition: all .5s;
}

a.text-muted:hover, a.text-muted:focus {
  color: #75859b !important;
}

.bg-light {
  background-color: #f8f9fc !important;
}

.bg-soft-light {
  color: #f8f9fc !important;
  background-color: rgba(248, 249, 252, .1) !important;
  border: 1px solid rgba(248, 249, 252, .1) !important;
}

.text-light {
  color: #f8f9fc !important;
}

a {
  transition: all .5s;
}

a.text-light:hover, a.text-light:focus {
  color: #e6eaf4 !important;
}

.bg-blue {
  background-color: #2f55d4 !important;
}

.bg-soft-blue {
  color: #2f55d4 !important;
  background-color: rgba(47, 85, 212, .1) !important;
  border: 1px solid rgba(47, 85, 212, .1) !important;
}

.text-blue {
  color: #2f55d4 !important;
}

a {
  transition: all .5s;
}

a.text-blue:hover, a.text-blue:focus {
  color: #284bc1 !important;
}

.bg-footer {
  background-color: #202942 !important;
}

.bg-soft-footer {
  color: #202942 !important;
  background-color: rgba(32, 41, 66, .1) !important;
  border: 1px solid rgba(32, 41, 66, .1) !important;
}

.text-footer {
  color: #202942 !important;
}

a {
  transition: all .5s;
}

a.text-footer:hover, a.text-footer:focus {
  color: #181e30 !important;
}

.bg-black {
  background: #161c2d !important;
}

.list-inline-item:not(:last-child) {
  margin-bottom: 5px;
  margin-right: 0;
}

.rounded {
  border-radius: 6px !important;
}

.rounded-top {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.rounded-left {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-right {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-lg {
  border-radius: 30px !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-top {
  border-top: 1px solid #e9ecef !important;
}

.border-bottom {
  border-bottom: 1px solid #e9ecef !important;
}

.border-left {
  border-left: 1px solid #e9ecef !important;
}

.border-right {
  border-right: 1px solid #e9ecef !important;
}

.small, small {
  font-size: 90%;
}

.card .card-body {
  padding: 1.5rem;
}

.component-wrapper .title {
  font-size: 20px;
}

.component-wrapper.sticky-bar li {
  margin-bottom: 6px;
}

.component-wrapper.sticky-bar li:last-child {
  margin-bottom: 0 !important;
}

.component-wrapper.sticky-bar li a {
  transition: all .5s;
}

.component-wrapper.sticky-bar li a:hover, .component-wrapper.sticky-bar li a:focus {
  color: #2f55d4 !important;
}

.btn-primary {
  box-shadow: 0 3px 5px rgba(47, 85, 212, .3);
  color: #fff !important;
  background-color: #2f55d4 !important;
  border: 1px solid #2f55d4 !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary.focus {
  color: #fff !important;
  background-color: #2443ac !important;
  border-color: #2443ac !important;
}

.btn-soft-primary {
  box-shadow: 0 3px 5px rgba(47, 85, 212, .3);
  color: #2f55d4 !important;
  background-color: rgba(47, 85, 212, .1) !important;
  border: 1px solid rgba(47, 85, 212, .1) !important;
}

.btn-soft-primary:hover, .btn-soft-primary:focus, .btn-soft-primary:active, .btn-soft-primary.active, .btn-soft-primary.focus {
  color: #fff !important;
  background-color: #2f55d4 !important;
  border-color: #2f55d4 !important;
}

.btn-outline-primary {
  color: #2f55d4;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #2f55d4;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.focus, .btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #2f55d4;
  border-color: #2f55d4;
  box-shadow: 0 3px 5px rgba(47, 85, 212, .3);
  color: #fff !important;
}

.btn-secondary {
  box-shadow: 0 3px 5px rgba(90, 109, 144, .3);
  color: #fff !important;
  background-color: #5a6d90 !important;
  border: 1px solid #5a6d90 !important;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .btn-secondary.focus {
  color: #fff !important;
  background-color: #465571 !important;
  border-color: #465571 !important;
}

.btn-soft-secondary {
  box-shadow: 0 3px 5px rgba(90, 109, 144, .3);
  color: #5a6d90 !important;
  background-color: rgba(90, 109, 144, .1) !important;
  border: 1px solid rgba(90, 109, 144, .1) !important;
}

.btn-soft-secondary:hover, .btn-soft-secondary:focus, .btn-soft-secondary:active, .btn-soft-secondary.active, .btn-soft-secondary.focus {
  color: #fff !important;
  background-color: #5a6d90 !important;
  border-color: #5a6d90 !important;
}

.btn-outline-secondary {
  color: #5a6d90;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #5a6d90;
}

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.focus, .btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #5a6d90;
  border-color: #5a6d90;
  box-shadow: 0 3px 5px rgba(90, 109, 144, .3);
  color: #fff !important;
}

.btn-success {
  box-shadow: 0 3px 5px rgba(46, 202, 139, .3);
  color: #fff !important;
  background-color: #2eca8b !important;
  border: 1px solid #2eca8b !important;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.focus {
  color: #fff !important;
  background-color: #25a06e !important;
  border-color: #25a06e !important;
}

.btn-soft-success {
  box-shadow: 0 3px 5px rgba(46, 202, 139, .3);
  color: #2eca8b !important;
  background-color: rgba(46, 202, 139, .1) !important;
  border: 1px solid rgba(46, 202, 139, .1) !important;
}

.btn-soft-success:hover, .btn-soft-success:focus, .btn-soft-success:active, .btn-soft-success.active, .btn-soft-success.focus {
  color: #fff !important;
  background-color: #2eca8b !important;
  border-color: #2eca8b !important;
}

.btn-outline-success {
  color: #2eca8b;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #2eca8b;
}

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.focus, .btn-outline-success:not(:disabled):not(.disabled):active {
  background-color: #2eca8b;
  border-color: #2eca8b;
  box-shadow: 0 3px 5px rgba(46, 202, 139, .3);
  color: #fff !important;
}

.btn-warning {
  box-shadow: 0 3px 5px rgba(241, 116, 37, .3);
  color: #fff !important;
  background-color: #f17425 !important;
  border: 1px solid #f17425 !important;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.focus {
  color: #fff !important;
  background-color: #d55b0e !important;
  border-color: #d55b0e !important;
}

.btn-soft-warning {
  box-shadow: 0 3px 5px rgba(241, 116, 37, .3);
  color: #f17425 !important;
  background-color: rgba(241, 116, 37, .1) !important;
  border: 1px solid rgba(241, 116, 37, .1) !important;
}

.btn-soft-warning:hover, .btn-soft-warning:focus, .btn-soft-warning:active, .btn-soft-warning.active, .btn-soft-warning.focus {
  color: #fff !important;
  background-color: #f17425 !important;
  border-color: #f17425 !important;
}

.btn-outline-warning {
  color: #f17425;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #f17425;
}

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.focus, .btn-outline-warning:not(:disabled):not(.disabled):active {
  background-color: #f17425;
  border-color: #f17425;
  box-shadow: 0 3px 5px rgba(241, 116, 37, .3);
  color: #fff !important;
}

.btn-info {
  box-shadow: 0 3px 5px rgba(23, 162, 184, .3);
  color: #fff !important;
  background-color: #17a2b8 !important;
  border: 1px solid #17a2b8 !important;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.focus {
  color: #fff !important;
  background-color: #117a8b !important;
  border-color: #117a8b !important;
}

.btn-soft-info {
  box-shadow: 0 3px 5px rgba(23, 162, 184, .3);
  color: #17a2b8 !important;
  background-color: rgba(23, 162, 184, .1) !important;
  border: 1px solid rgba(23, 162, 184, .1) !important;
}

.btn-soft-info:hover, .btn-soft-info:focus, .btn-soft-info:active, .btn-soft-info.active, .btn-soft-info.focus {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.btn-outline-info {
  color: #17a2b8;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #17a2b8;
}

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.focus, .btn-outline-info:not(:disabled):not(.disabled):active {
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: 0 3px 5px rgba(23, 162, 184, .3);
  color: #fff !important;
}

.btn-danger {
  box-shadow: 0 3px 5px rgba(228, 63, 82, .3);
  color: #fff !important;
  background-color: #e43f52 !important;
  border: 1px solid #e43f52 !important;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger.focus {
  color: #fff !important;
  background-color: #d21e32 !important;
  border-color: #d21e32 !important;
}

.btn-soft-danger {
  box-shadow: 0 3px 5px rgba(228, 63, 82, .3);
  color: #e43f52 !important;
  background-color: rgba(228, 63, 82, .1) !important;
  border: 1px solid rgba(228, 63, 82, .1) !important;
}

.btn-soft-danger:hover, .btn-soft-danger:focus, .btn-soft-danger:active, .btn-soft-danger.active, .btn-soft-danger.focus {
  color: #fff !important;
  background-color: #e43f52 !important;
  border-color: #e43f52 !important;
}

.btn-outline-danger {
  color: #e43f52;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #e43f52;
}

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.focus, .btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: #e43f52;
  border-color: #e43f52;
  box-shadow: 0 3px 5px rgba(228, 63, 82, .3);
  color: #fff !important;
}

.btn-dark {
  box-shadow: 0 3px 5px rgba(60, 72, 88, .3);
  color: #fff !important;
  background-color: #3c4858 !important;
  border: 1px solid #3c4858 !important;
}

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark.focus {
  color: #fff !important;
  background-color: #272f3a !important;
  border-color: #272f3a !important;
}

.btn-soft-dark {
  box-shadow: 0 3px 5px rgba(60, 72, 88, .3);
  color: #3c4858 !important;
  background-color: rgba(60, 72, 88, .1) !important;
  border: 1px solid rgba(60, 72, 88, .1) !important;
}

.btn-soft-dark:hover, .btn-soft-dark:focus, .btn-soft-dark:active, .btn-soft-dark.active, .btn-soft-dark.focus {
  color: #fff !important;
  background-color: #3c4858 !important;
  border-color: #3c4858 !important;
}

.btn-outline-dark {
  color: #3c4858;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #3c4858;
}

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.focus, .btn-outline-dark:not(:disabled):not(.disabled):active {
  background-color: #3c4858;
  border-color: #3c4858;
  box-shadow: 0 3px 5px rgba(60, 72, 88, .3);
  color: #fff !important;
}

.btn-muted {
  box-shadow: 0 3px 5px rgba(132, 146, 166, .3);
  color: #fff !important;
  background-color: #8492a6 !important;
  border: 1px solid #8492a6 !important;
}

.btn-muted:hover, .btn-muted:focus, .btn-muted:active, .btn-muted.active, .btn-muted.focus {
  color: #fff !important;
  background-color: #68788f !important;
  border-color: #68788f !important;
}

.btn-soft-muted {
  box-shadow: 0 3px 5px rgba(132, 146, 166, .3);
  color: #8492a6 !important;
  background-color: rgba(132, 146, 166, .1) !important;
  border: 1px solid rgba(132, 146, 166, .1) !important;
}

.btn-soft-muted:hover, .btn-soft-muted:focus, .btn-soft-muted:active, .btn-soft-muted.active, .btn-soft-muted.focus {
  color: #fff !important;
  background-color: #8492a6 !important;
  border-color: #8492a6 !important;
}

.btn-outline-muted {
  color: #8492a6;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #8492a6;
}

.btn-outline-muted:hover, .btn-outline-muted:focus, .btn-outline-muted:active, .btn-outline-muted.active, .btn-outline-muted.focus, .btn-outline-muted:not(:disabled):not(.disabled):active {
  background-color: #8492a6;
  border-color: #8492a6;
  box-shadow: 0 3px 5px rgba(132, 146, 166, .3);
  color: #fff !important;
}

.btn-light {
  box-shadow: 0 3px 5px rgba(248, 249, 252, .3);
  color: #fff !important;
  background-color: #f8f9fc !important;
  border: 1px solid #f8f9fc !important;
}

.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active, .btn-light.focus {
  color: #fff !important;
  background-color: #d4daed !important;
  border-color: #d4daed !important;
}

.btn-soft-light {
  box-shadow: 0 3px 5px rgba(248, 249, 252, .3);
  color: #f8f9fc !important;
  background-color: rgba(248, 249, 252, .1) !important;
  border: 1px solid rgba(248, 249, 252, .1) !important;
}

.btn-soft-light:hover, .btn-soft-light:focus, .btn-soft-light:active, .btn-soft-light.active, .btn-soft-light.focus {
  color: #fff !important;
  background-color: #f8f9fc !important;
  border-color: #f8f9fc !important;
}

.btn-outline-light {
  color: #f8f9fc;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #f8f9fc;
}

.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.focus, .btn-outline-light:not(:disabled):not(.disabled):active {
  background-color: #f8f9fc;
  border-color: #f8f9fc;
  box-shadow: 0 3px 5px rgba(248, 249, 252, .3);
  color: #fff !important;
}

.btn-blue {
  box-shadow: 0 3px 5px rgba(47, 85, 212, .3);
  color: #fff !important;
  background-color: #2f55d4 !important;
  border: 1px solid #2f55d4 !important;
}

.btn-blue:hover, .btn-blue:focus, .btn-blue:active, .btn-blue.active, .btn-blue.focus {
  color: #fff !important;
  background-color: #2443ac !important;
  border-color: #2443ac !important;
}

.btn-soft-blue {
  box-shadow: 0 3px 5px rgba(47, 85, 212, .3);
  color: #2f55d4 !important;
  background-color: rgba(47, 85, 212, .1) !important;
  border: 1px solid rgba(47, 85, 212, .1) !important;
}

.btn-soft-blue:hover, .btn-soft-blue:focus, .btn-soft-blue:active, .btn-soft-blue.active, .btn-soft-blue.focus {
  color: #fff !important;
  background-color: #2f55d4 !important;
  border-color: #2f55d4 !important;
}

.btn-outline-blue {
  color: #2f55d4;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #2f55d4;
}

.btn-outline-blue:hover, .btn-outline-blue:focus, .btn-outline-blue:active, .btn-outline-blue.active, .btn-outline-blue.focus, .btn-outline-blue:not(:disabled):not(.disabled):active {
  background-color: #2f55d4;
  border-color: #2f55d4;
  box-shadow: 0 3px 5px rgba(47, 85, 212, .3);
  color: #fff !important;
}

.btn-footer {
  box-shadow: 0 3px 5px rgba(32, 41, 66, .3);
  color: #fff !important;
  background-color: #202942 !important;
  border: 1px solid #202942 !important;
}

.btn-footer:hover, .btn-footer:focus, .btn-footer:active, .btn-footer.active, .btn-footer.focus {
  color: #fff !important;
  background-color: #0f131f !important;
  border-color: #0f131f !important;
}

.btn-soft-footer {
  box-shadow: 0 3px 5px rgba(32, 41, 66, .3);
  color: #202942 !important;
  background-color: rgba(32, 41, 66, .1) !important;
  border: 1px solid rgba(32, 41, 66, .1) !important;
}

.btn-soft-footer:hover, .btn-soft-footer:focus, .btn-soft-footer:active, .btn-soft-footer.active, .btn-soft-footer.focus {
  color: #fff !important;
  background-color: #202942 !important;
  border-color: #202942 !important;
}

.btn-outline-footer {
  color: #202942;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #202942;
}

.btn-outline-footer:hover, .btn-outline-footer:focus, .btn-outline-footer:active, .btn-outline-footer.active, .btn-outline-footer.focus, .btn-outline-footer:not(:disabled):not(.disabled):active {
  background-color: #202942;
  border-color: #202942;
  box-shadow: 0 3px 5px rgba(32, 41, 66, .3);
  color: #fff !important;
}

.btn {
  letter-spacing: .5px;
  border-radius: 6px;
  outline: none;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
}

.btn:focus {
  box-shadow: none !important;
}

.btn.btn-sm {
  padding: 7px 16px;
  font-size: 10px;
}

.btn.btn-lg {
  padding: 14px 30px;
  font-size: 16px;
}

.btn.searchbtn {
  padding: 6px 20px;
}

.btn.btn-pills {
  border-radius: 30px;
}

.btn.btn-light {
  color: #3c4858 !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-light:hover, .btn.btn-light:focus, .btn.btn-light:active, .btn.btn-light.active, .btn.btn-light.focus {
  color: #3c4858 !important;
  background-color: #d4daed !important;
}

.btn.btn-soft-light {
  color: rgba(60, 72, 88, .5) !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-soft-light:hover, .btn.btn-soft-light:focus, .btn.btn-soft-light:active, .btn.btn-soft-light.active, .btn.btn-soft-light.focus {
  color: #3c4858 !important;
}

.btn.btn-outline-light {
  background-color: rgba(0, 0, 0, 0);
  color: #3c4858 !important;
  border: 1px solid #dee2e6 !important;
}

.btn.btn-outline-light:hover, .btn.btn-outline-light:focus, .btn.btn-outline-light:active, .btn.btn-outline-light.active, .btn.btn-outline-light.focus {
  background-color: #f8f9fc !important;
}

.btn.btn-icon {
  height: 36px;
  width: 36px;
  padding: 0;
  line-height: 34px;
}

.btn.btn-icon .icons {
  height: 16px;
  width: 16px;
  font-size: 16px;
}

.btn.btn-icon.btn-lg {
  height: 48px;
  width: 48px;
  line-height: 46px;
}

.btn.btn-icon.btn-lg .icons {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.btn.btn-icon.btn-sm {
  height: 30px;
  width: 30px;
  line-height: 28px;
}

button:not(:disabled) {
  outline: none;
}

.shadow {
  box-shadow: 0 0 3px rgba(60, 72, 88, .15) !important;
}

.shadow-lg {
  box-shadow: 0 10px 25px rgba(60, 72, 88, .15) !important;
}

.shadow-md {
  box-shadow: 0 5px 13px rgba(60, 72, 88, .2) !important;
}

.badge-primary {
  color: #fff !important;
  background-color: #2f55d4 !important;
}

.badge-outline-primary {
  color: #2f55d4 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #2f55d4 !important;
}

.badge-soft-primary {
  color: #2f55d4 !important;
  background-color: rgba(47, 85, 212, .1) !important;
  border: 1px solid rgba(47, 85, 212, .1) !important;
}

.badge-secondary {
  color: #fff !important;
  background-color: #5a6d90 !important;
}

.badge-outline-secondary {
  color: #5a6d90 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #5a6d90 !important;
}

.badge-soft-secondary {
  color: #5a6d90 !important;
  background-color: rgba(90, 109, 144, .1) !important;
  border: 1px solid rgba(90, 109, 144, .1) !important;
}

.badge-success {
  color: #fff !important;
  background-color: #2eca8b !important;
}

.badge-outline-success {
  color: #2eca8b !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #2eca8b !important;
}

.badge-soft-success {
  color: #2eca8b !important;
  background-color: rgba(46, 202, 139, .1) !important;
  border: 1px solid rgba(46, 202, 139, .1) !important;
}

.badge-warning {
  color: #fff !important;
  background-color: #f17425 !important;
}

.badge-outline-warning {
  color: #f17425 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #f17425 !important;
}

.badge-soft-warning {
  color: #f17425 !important;
  background-color: rgba(241, 116, 37, .1) !important;
  border: 1px solid rgba(241, 116, 37, .1) !important;
}

.badge-info {
  color: #fff !important;
  background-color: #17a2b8 !important;
}

.badge-outline-info {
  color: #17a2b8 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #17a2b8 !important;
}

.badge-soft-info {
  color: #17a2b8 !important;
  background-color: rgba(23, 162, 184, .1) !important;
  border: 1px solid rgba(23, 162, 184, .1) !important;
}

.badge-danger {
  color: #fff !important;
  background-color: #e43f52 !important;
}

.badge-outline-danger {
  color: #e43f52 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #e43f52 !important;
}

.badge-soft-danger {
  color: #e43f52 !important;
  background-color: rgba(228, 63, 82, .1) !important;
  border: 1px solid rgba(228, 63, 82, .1) !important;
}

.badge-dark {
  color: #fff !important;
  background-color: #3c4858 !important;
}

.badge-outline-dark {
  color: #3c4858 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #3c4858 !important;
}

.badge-soft-dark {
  color: #3c4858 !important;
  background-color: rgba(60, 72, 88, .1) !important;
  border: 1px solid rgba(60, 72, 88, .1) !important;
}

.badge-muted {
  color: #fff !important;
  background-color: #8492a6 !important;
}

.badge-outline-muted {
  color: #8492a6 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #8492a6 !important;
}

.badge-soft-muted {
  color: #8492a6 !important;
  background-color: rgba(132, 146, 166, .1) !important;
  border: 1px solid rgba(132, 146, 166, .1) !important;
}

.badge-light {
  color: #fff !important;
  background-color: #f8f9fc !important;
}

.badge-outline-light {
  color: #f8f9fc !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #f8f9fc !important;
}

.badge-soft-light {
  color: #f8f9fc !important;
  background-color: rgba(248, 249, 252, .1) !important;
  border: 1px solid rgba(248, 249, 252, .1) !important;
}

.badge-blue {
  color: #fff !important;
  background-color: #2f55d4 !important;
}

.badge-outline-blue {
  color: #2f55d4 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #2f55d4 !important;
}

.badge-soft-blue {
  color: #2f55d4 !important;
  background-color: rgba(47, 85, 212, .1) !important;
  border: 1px solid rgba(47, 85, 212, .1) !important;
}

.badge-footer {
  color: #fff !important;
  background-color: #202942 !important;
}

.badge-outline-footer {
  color: #202942 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #202942 !important;
}

.badge-soft-footer {
  color: #202942 !important;
  background-color: rgba(32, 41, 66, .1) !important;
  border: 1px solid rgba(32, 41, 66, .1) !important;
}

.badge {
  letter-spacing: .5px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 4px 8px;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
}

.badge.badge-light {
  color: #3c4858 !important;
  background-color: #f8f9fc !important;
}

.badge.badge-outline-light {
  color: #3c4858 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #fff !important;
}

.badge.badge-pill {
  border-radius: 12px;
}

.badge:focus, .badge.focus {
  box-shadow: none !important;
}

.dropdown-primary .dropdown-menu .dropdown-item:hover, .dropdown-primary .dropdown-menu .dropdown-item.active, .dropdown-primary .dropdown-menu .dropdown-item:active, .dropdown-primary .dropdown-menu .dropdown-item.focus, .dropdown-primary .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #2f55d4 !important;
}

.dropdown-secondary .dropdown-menu .dropdown-item:hover, .dropdown-secondary .dropdown-menu .dropdown-item.active, .dropdown-secondary .dropdown-menu .dropdown-item:active, .dropdown-secondary .dropdown-menu .dropdown-item.focus, .dropdown-secondary .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #5a6d90 !important;
}

.dropdown-success .dropdown-menu .dropdown-item:hover, .dropdown-success .dropdown-menu .dropdown-item.active, .dropdown-success .dropdown-menu .dropdown-item:active, .dropdown-success .dropdown-menu .dropdown-item.focus, .dropdown-success .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #2eca8b !important;
}

.dropdown-warning .dropdown-menu .dropdown-item:hover, .dropdown-warning .dropdown-menu .dropdown-item.active, .dropdown-warning .dropdown-menu .dropdown-item:active, .dropdown-warning .dropdown-menu .dropdown-item.focus, .dropdown-warning .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #f17425 !important;
}

.dropdown-info .dropdown-menu .dropdown-item:hover, .dropdown-info .dropdown-menu .dropdown-item.active, .dropdown-info .dropdown-menu .dropdown-item:active, .dropdown-info .dropdown-menu .dropdown-item.focus, .dropdown-info .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #17a2b8 !important;
}

.dropdown-danger .dropdown-menu .dropdown-item:hover, .dropdown-danger .dropdown-menu .dropdown-item.active, .dropdown-danger .dropdown-menu .dropdown-item:active, .dropdown-danger .dropdown-menu .dropdown-item.focus, .dropdown-danger .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #e43f52 !important;
}

.dropdown-dark .dropdown-menu .dropdown-item:hover, .dropdown-dark .dropdown-menu .dropdown-item.active, .dropdown-dark .dropdown-menu .dropdown-item:active, .dropdown-dark .dropdown-menu .dropdown-item.focus, .dropdown-dark .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #3c4858 !important;
}

.dropdown-muted .dropdown-menu .dropdown-item:hover, .dropdown-muted .dropdown-menu .dropdown-item.active, .dropdown-muted .dropdown-menu .dropdown-item:active, .dropdown-muted .dropdown-menu .dropdown-item.focus, .dropdown-muted .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #8492a6 !important;
}

.dropdown-light .dropdown-menu .dropdown-item:hover, .dropdown-light .dropdown-menu .dropdown-item.active, .dropdown-light .dropdown-menu .dropdown-item:active, .dropdown-light .dropdown-menu .dropdown-item.focus, .dropdown-light .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #f8f9fc !important;
}

.dropdown-blue .dropdown-menu .dropdown-item:hover, .dropdown-blue .dropdown-menu .dropdown-item.active, .dropdown-blue .dropdown-menu .dropdown-item:active, .dropdown-blue .dropdown-menu .dropdown-item.focus, .dropdown-blue .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #2f55d4 !important;
}

.dropdown-footer .dropdown-menu .dropdown-item:hover, .dropdown-footer .dropdown-menu .dropdown-item.active, .dropdown-footer .dropdown-menu .dropdown-item:active, .dropdown-footer .dropdown-menu .dropdown-item.focus, .dropdown-footer .dropdown-menu .dropdown-item:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #202942 !important;
}

.btn-group .dropdown-toggle:after {
  content: "";
  vertical-align: 0;
  border: 2px solid #fff;
  border-width: 0 2px 2px 0;
  border-radius: .5px;
  margin-left: 0;
  padding: 3px;
  position: relative;
  top: -2px;
  right: -4px;
  transform: rotate(45deg);
}

.btn-group .dropdown-menu {
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0 0 3px rgba(60, 72, 88, .15);
}

.btn-group .dropdown-menu:before {
  content: "";
  box-sizing: border-box;
  transform-origin: 0 0;
  border: 7px solid rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff;
  border-radius: .5px;
  position: absolute;
  top: 2px;
  left: 45px;
  transform: rotate(135deg);
  box-shadow: -2px 2px 2px -1px rgba(22, 28, 45, .15);
}

.alert-primary {
  color: #fff;
  background-color: rgba(47, 85, 212, .9);
  border-color: #2f55d4;
}

.alert-primary .alert-link {
  color: #122258;
}

.alert-outline-primary {
  color: #2f55d4;
  background-color: #fff;
  border-color: #2f55d4;
}

.alert-secondary {
  color: #fff;
  background-color: rgba(90, 109, 144, .9);
  border-color: #5a6d90;
}

.alert-secondary .alert-link {
  color: #1f2632;
}

.alert-outline-secondary {
  color: #5a6d90;
  background-color: #fff;
  border-color: #5a6d90;
}

.alert-success {
  color: #fff;
  background-color: rgba(46, 202, 139, .9);
  border-color: #2eca8b;
}

.alert-success .alert-link {
  color: #124d35;
}

.alert-outline-success {
  color: #2eca8b;
  background-color: #fff;
  border-color: #2eca8b;
}

.alert-warning {
  color: #fff;
  background-color: rgba(241, 116, 37, .9);
  border-color: #f17425;
}

.alert-warning .alert-link {
  color: #753208;
}

.alert-outline-warning {
  color: #f17425;
  background-color: #fff;
  border-color: #f17425;
}

.alert-info {
  color: #fff;
  background-color: rgba(23, 162, 184, .9);
  border-color: #17a2b8;
}

.alert-info .alert-link {
  color: #062a30;
}

.alert-outline-info {
  color: #17a2b8;
  background-color: #fff;
  border-color: #17a2b8;
}

.alert-danger {
  color: #fff;
  background-color: rgba(228, 63, 82, .9);
  border-color: #e43f52;
}

.alert-danger .alert-link {
  color: #79111d;
}

.alert-outline-danger {
  color: #e43f52;
  background-color: #fff;
  border-color: #e43f52;
}

.alert-dark {
  color: #fff;
  background-color: rgba(60, 72, 88, .9);
  border-color: #3c4858;
}

.alert-dark .alert-link {
  color: #000;
}

.alert-outline-dark {
  color: #3c4858;
  background-color: #fff;
  border-color: #3c4858;
}

.alert-muted {
  color: #fff;
  background-color: rgba(132, 146, 166, .9);
  border-color: #8492a6;
}

.alert-muted .alert-link {
  color: #3d4654;
}

.alert-outline-muted {
  color: #8492a6;
  background-color: #fff;
  border-color: #8492a6;
}

.alert-light {
  color: #fff;
  background-color: rgba(248, 249, 252, .9);
  border-color: #f8f9fc;
}

.alert-light .alert-link {
  color: #8d9dce;
}

.alert-outline-light {
  color: #f8f9fc;
  background-color: #fff;
  border-color: #f8f9fc;
}

.alert-blue {
  color: #fff;
  background-color: rgba(47, 85, 212, .9);
  border-color: #2f55d4;
}

.alert-blue .alert-link {
  color: #122258;
}

.alert-outline-blue {
  color: #2f55d4;
  background-color: #fff;
  border-color: #2f55d4;
}

.alert-footer {
  color: #fff;
  background-color: rgba(32, 41, 66, .9);
  border-color: #202942;
}

.alert-footer .alert-link {
  color: #000;
}

.alert-outline-footer {
  color: #202942;
  background-color: #fff;
  border-color: #202942;
}

.alert {
  border-radius: 6px;
  padding: 8px 15px;
  font-size: 14px;
}

.alert.alert-light {
  color: #3c4858;
  background-color: #f8f9fc;
  border-color: #dee2e6;
}

.alert.alert-dismissible {
  padding-right: 30px;
}

.alert.alert-dismissible .close {
  top: -2px;
  right: -10px;
  font-size: 16px !important;
}

.alert.alert-pills {
  border-radius: 30px;
  display: inline-block;
}

.alert.alert-pills .content {
  font-weight: 600;
}

.page-next-level .title {
  letter-spacing: 1px;
  font-size: 28px;
}

.page-next-level .page-next {
  z-index: 99;
  position: relative;
  top: 110px;
}

@media (max-width: 768px) {
  .page-next-level .page-next {
    top: 70px;
  }
}

.breadcrumb {
  letter-spacing: .5px;
  padding: 8px 24px;
  display: block;
}

.breadcrumb .breadcrumb-item {
  text-transform: uppercase;
  padding-left: 0;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
}

.breadcrumb .breadcrumb-item a {
  color: #3c4858;
}

.breadcrumb .breadcrumb-item a:hover, .breadcrumb .breadcrumb-item.active {
  color: #2f55d4;
}

.breadcrumb .breadcrumb-item:before {
  content: "";
}

.breadcrumb .breadcrumb-item:after {
  color: #3c4858;
  padding-left: 5px;
  font-family: Material Design Icons;
  font-size: 14px;
  content: "󰅂" !important;
}

.breadcrumb .breadcrumb-item:last-child:after {
  display: none;
}

.pagination .page-item:first-child .page-link {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.pagination .page-item:last-child .page-link {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.pagination .page-item .page-link {
  color: #3c4858;
  border: 1px solid #dee2e6;
  padding: 10px 15px;
  font-size: 14px;
}

.pagination .page-item .page-link:focus {
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  color: #fff;
  background: rgba(47, 85, 212, .9);
  border-color: rgba(47, 85, 212, .9);
}

.pagination .page-item.active .page-link {
  color: #fff;
  cursor: not-allowed;
  border-color: #2f55d4;
  background: #2f55d4 !important;
}

.avatar.avatar-ex-sm {
  max-height: 60px;
}

.avatar.avatar-md-sm {
  height: 45px;
  width: 45px;
}

.avatar.avatar-small {
  height: 65px;
  width: 65px;
}

.avatar.avatar-md-md {
  height: 80px;
  width: 80px;
}

.avatar.avatar-medium {
  height: 110px;
  width: 110px;
}

.avatar.avatar-large {
  height: 140px;
  width: 140px;
}

.avatar.avatar-ex-large {
  height: 180px;
  width: 180px;
}

.faq-container .question {
  font-size: 20px;
}

.faq-content .card .faq .card-header {
  padding-right: 40px !important;
}

.faq-content .card a.faq[data-toggle="collapse"] {
  color: #2f55d4;
  background-color: #fff;
  transition: all .5s;
}

.faq-content .card a.faq[data-toggle="collapse"]:before {
  content: "󰅃";
  color: #2f55d4;
  font-family: Material Design Icons;
  font-size: 18px;
  transition: all .5s;
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.faq-content .card a.faq[data-toggle="collapse"].collapsed {
  background-color: #fff;
  transition: all .5s;
  color: #3c4858 !important;
}

.faq-content .card a.faq[data-toggle="collapse"].collapsed:before {
  top: 15px;
  transform: rotate(180deg);
  color: #3c4858 !important;
}

.nav-pills {
  background: #f4f6fa;
  margin-bottom: 15px;
}

.nav-pills .nav-link {
  padding: 5px;
  transition: all .5s;
  color: #495057 !important;
}

.nav-pills .nav-link.nav-link-alt {
  color: #fff !important;
}

.nav-pills .nav-link.nav-link-alt.active {
  background: #3c4858 !important;
}

.nav-pills .nav-link.active {
  background: #2f55d4;
  color: #fff !important;
}

.nav-pills .nav-link.active .tab-para {
  color: rgba(255, 255, 255, .65) !important;
}

.progress-box .title {
  font-size: 15px;
}

.progress-box .progress {
  height: 8px;
  overflow: visible;
}

.progress-box .progress .progress-bar {
  border-radius: 6px;
  animation: animate-positive 3s;
  overflow: visible !important;
}

.progress-box .progress .progress-value {
  font-size: 13px;
  position: absolute;
  top: -25px;
  right: -15px;
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.blockquote {
  border-left: 3px solid #dee2e6;
  border-radius: 6px;
  font-size: 16px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-size: 14px;
  font-weight: 700;
}

.form-group .form-control {
  box-shadow: none;
  color: #3c4858;
  height: 42px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  font-size: 13px;
  transition: all .5s;
}

.form-group .form-control:focus, .form-group .form-control.active {
  border-color: #2f55d4;
}

.form-group textarea {
  height: 120px !important;
}

.form-group textarea.form-control {
  line-height: 25px;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #2f55d4;
  border-color: #2f55d4;
}

.custom-control-input:focus ~ .custom-control-label:before, .form-control:focus {
  box-shadow: none;
  border-color: #2f55d4;
}

.custom-control-label {
  cursor: pointer;
}

.custom-control-label:before, .custom-control-label:after {
  top: 2px;
}

.custom-switch .custom-control-label:after {
  top: 4px;
}

.subcribe-form input {
  width: 100%;
  background-color: rgba(255, 255, 255, .8);
  border: none;
  padding: 14px 160px 14px 30px;
  color: #3c4858 !important;
  outline: none !important;
}

.subcribe-form button {
  position: absolute;
  top: 6px;
  right: 6px;
  outline: none !important;
}

.subcribe-form form {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
}

.table-responsive .table td, .table-responsive .table th {
  vertical-align: middle;
}

.table-responsive .table.table-nowrap th, .table-responsive .table.table-nowrap td {
  white-space: nowrap;
}

.table-responsive .table-center th {
  vertical-align: middle !important;
}

.table-responsive .table-center tbody tr:hover {
  color: #3c4858;
  background-color: #f8f9fa;
}

.table-responsive .table-center.invoice-tb th:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)), .table-responsive .table-center.invoice-tb td:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: right;
}

.table-responsive .table-center.invoice-tb th:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)), .table-responsive .table-center.invoice-tb td:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.table-responsive .table-center.invoice-tb th:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)), .table-responsive .table-center.invoice-tb td:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.modal-open {
  padding-right: 0 !important;
}

#preloader {
  z-index: 10000000;
  background-image: linear-gradient(45deg, #fff, #fff);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#preloader #status {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

#preloader #status .spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  position: relative;
}

#preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  opacity: .6;
  background-color: #2f55d4;
  border-radius: 50%;
  animation: sk-bounce 2s ease-in-out infinite;
  position: absolute;
  top: 0;
  left: 0;
}

#preloader #status .spinner .double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.social-icon li a {
  color: #3c4858;
  height: 32px;
  width: 32px;
  text-align: center;
  border: 1px solid #3c4858;
  line-height: 28px;
  transition: all .4s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.social-icon li a .fea-social {
  stroke-width: 2px;
}

.social-icon li a:hover {
  background-color: #2f55d4;
  color: #fff !important;
  border-color: #2f55d4 !important;
}

.social-icon li a:hover .fea-social {
  fill: #2f55d4;
}

.social-icon.social li a {
  color: #adb5bd;
  border-color: #adb5bd;
}

.back-to-top {
  z-index: 99;
  transition: all .5s;
  display: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.back-to-top .icons {
  transition: all .5s;
}

.back-to-top:hover {
  transform: rotate(45deg);
}

.back-to-top:hover .icons {
  transform: rotate(-45deg);
}

.back-to-home {
  z-index: 1;
  position: fixed;
  top: 4%;
  right: 2%;
}

.sticky-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.fea {
  stroke-width: 1.8px;
}

.fea.icon-sm {
  height: 16px;
  width: 16px;
}

.fea.icon-ex-md {
  height: 20px;
  width: 20px;
}

.fea.icon-m-md {
  height: 28px;
  width: 28px;
}

.fea.icon-md {
  height: 35px;
  width: 35px;
}

.fea.icon-lg {
  height: 42px;
  width: 42px;
}

.fea.icon-ex-lg {
  height: 52px;
  width: 52px;
}

[class^="uil-"]:before, [class*=" uil-"]:before {
  margin: 0;
}

.uim-svg {
  vertical-align: 0 !important;
}

@media (max-width: 768px) {
  .display-1 {
    font-size: 56px !important;
  }

  .display-2 {
    font-size: 50px !important;
  }

  .display-3 {
    font-size: 42px !important;
  }

  .display-4 {
    font-size: 36px !important;
  }
}

body {
  color: #161c2d;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  overflow-x: hidden !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: Nunito, sans-serif;
  font-weight: 600;
  line-height: 1.5;
}

::selection {
  color: #fff;
  background: rgba(47, 85, 212, .9);
}

a {
  text-decoration: none !important;
}

p {
  line-height: 1.6;
}

.section {
  padding: 100px 0;
  position: relative;
}

@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }
}

.section-two {
  padding: 60px 0;
  position: relative;
}

.bg-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(60, 72, 88, .7);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-overlay-gradient {
  background-color: rgba(47, 85, 212, .6);
}

.bg-overlay-white {
  background-color: rgba(255, 255, 255, .5);
}

.bg-overlay-gradient2 {
  opacity: .5;
  background-image: linear-gradient(90deg, #2eca8b, #2f55d4, #2eca8b);
}

.title-heading {
  line-height: 26px;
}

.title-heading .heading {
  letter-spacing: 1px;
  font-size: 45px !important;
}

@media (max-width: 768px) {
  .title-heading .heading {
    font-size: 35px !important;
  }
}

.title-heading .para-desc {
  font-size: 18px;
}

.section-title {
  position: relative;
}

.section-title .title {
  letter-spacing: .5px;
  font-size: 30px !important;
}

@media (max-width: 768px) {
  .section-title .title {
    font-size: 24px !important;
  }
}

.text-shadow-title {
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 4px rgba(255, 255, 255, .4), 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}

.para-desc {
  max-width: 600px;
}

.mt-100 {
  margin-top: 100px;
}

.shape {
  pointer-events: none;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
}

.shape > svg {
  width: 100%;
  height: auto;
  transform-origin: top;
  transform: scale(2);
}

.shape.integration-hero {
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  height: 250px;
  background: #fff;
}

@media (max-width: 768px) {
  .shape.integration-hero {
    height: 140px;
  }
}

@media (max-width: 425px) {
  .shape.integration-hero {
    height: 60px;
  }
}

@media (max-width: 425px) {
  .shape {
    bottom: -4px;
  }
}

.mover {
  animation: mover 1s infinite alternate;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(5px);
  }
}

.feature-posts-placeholder {
  height: 66.6%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 425px) {
  .feature-posts-placeholder {
    height: 80%;
  }
}

.spin-anything {
  animation: spinner-animation 5s linear infinite;
}

@keyframes spinner-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.seo-hero-widget {
  transition: all .5s;
  position: relative;
}

.seo-hero-widget:hover {
  z-index: 9;
  transform: scale(1.05);
}

.home-wrapper:before {
  content: "";
  width: 90rem;
  height: 35rem;
  z-index: 1;
  background: linear-gradient(90deg, rgba(47, 85, 212, .3), rgba(0, 0, 0, 0));
  position: absolute;
  bottom: 40rem;
  right: 0;
  transform: rotate(78.75deg);
}

@media (min-width: 769px) and (max-width: 1024px) {
  .home-wrapper:before {
    right: -20rem;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .home-wrapper:before {
    width: 70rem;
    height: 25rem;
    right: -15rem;
  }
}

@media (max-width: 425px) {
  .home-wrapper:before {
    right: -25rem;
  }
}

@media (min-width: 768px) {
  .margin-top-100 {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .mt-60 {
    margin-top: 60px;
  }
}

#topnav {
  z-index: 1030;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

#topnav .logo {
  float: left;
  color: #3c4858 !important;
}

#topnav .logo .l-dark {
  display: none;
}

#topnav .logo .l-light {
  display: inline-block;
}

#topnav .has-submenu.active a {
  color: #fff;
}

#topnav .has-submenu.active .submenu li.active > a {
  color: #2f55d4 !important;
}

#topnav .has-submenu.active.active .menu-arrow {
  border-color: #2f55d4;
}

#topnav .has-submenu {
  position: relative;
}

#topnav .has-submenu .submenu .submenu-arrow {
  border: 2px solid #3c4858;
  border-width: 0 2px 2px 0;
  border-radius: .5px;
  padding: 3px;
  display: inline-block;
  position: absolute;
  top: 14px;
  right: 20px;
  transform: rotate(-45deg);
}

#topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  border-color: #2f55d4;
}

#topnav .navbar-toggle {
  width: 60px;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
}

#topnav .navbar-toggle .lines {
  width: 25px;
  height: 18px;
  margin: 28px 20px;
  display: block;
  position: relative;
}

#topnav .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: #3c4858;
  margin-bottom: 5px;
  transition: transform .5s;
  display: block;
}

#topnav .navbar-toggle span:last-child {
  margin-bottom: 0;
}

#topnav .buy-button {
  float: right;
  line-height: 68px;
}

#topnav .buy-button > li {
  line-height: initial;
}

#topnav .buy-button .login-btn-primary {
  display: none;
}

#topnav .buy-button .login-btn-light {
  display: inline-block;
}

#topnav .buy-button .dropdown .dropdown-toggle:after, #topnav .buy-menu-btn .dropdown .dropdown-toggle:after {
  display: none;
}

#topnav .navbar-toggle.open span {
  position: absolute;
}

#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  transform: rotate(45deg);
}

#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}

#topnav .navbar-toggle.open span:last-child {
  width: 100%;
  top: 6px;
  transform: rotate(-45deg);
}

#topnav .navbar-toggle.open span:hover {
  background-color: #2f55d4;
}

#topnav .navbar-toggle:hover, #topnav .navbar-toggle:focus, #topnav .navbar-toggle .navigation-menu > li > a:hover, #topnav .navbar-toggle:focus {
  background-color: rgba(0, 0, 0, 0);
}

#topnav .navigation-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

#topnav .navigation-menu > li {
  float: left;
  margin: 0 10px;
  display: block;
  position: relative;
}

#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
  color: #2f55d4 !important;
}

#topnav .navigation-menu > li > a {
  color: #3c4858;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-left: 15px;
  padding-right: 15px;
  font-family: Nunito, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  transition: all .5s;
  display: block;
  background-color: rgba(0, 0, 0, 0) !important;
}

#topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li > a:active {
  color: #2f55d4;
}

#topnav .navigation-menu .has-submenu .menu-arrow {
  border: 2px solid #3c4858;
  border-width: 0 2px 2px 0;
  border-radius: .5px;
  padding: 3px;
  transition: all .5s;
  display: inline-block;
  position: absolute;
  top: 30px;
  right: -1px;
  transform: rotate(45deg);
}

#topnav .navigation-menu .has-submenu:hover .menu-arrow {
  transform: rotate(225deg);
}

#topnav .menu-extras {
  float: right;
}

#topnav.scroll {
  background-color: #fff;
  border: none;
  box-shadow: 0 0 3px rgba(60, 72, 88, .15);
}

#topnav.scroll .navigation-menu > li > a {
  color: #3c4858;
}

#topnav.scroll .navigation-menu > li > .menu-arrow {
  border-color: #3c4858;
}

#topnav.scroll .navigation-menu > li:hover > a, #topnav.scroll .navigation-menu > li.active > a {
  color: #2f55d4;
}

#topnav.scroll .navigation-menu > li:hover > .menu-arrow, #topnav.scroll .navigation-menu > li.active > .menu-arrow {
  border-color: #2f55d4;
}

#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}

#topnav.defaultscroll.scroll .logo, #topnav.defaultscroll.scroll.dark-menubar .logo {
  line-height: 62px;
}

#topnav.nav-sticky {
  background: #fff;
  box-shadow: 0 0 3px rgba(60, 72, 88, .15);
}

#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  color: #3c4858;
}

#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: #2f55d4 !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  border-color: #2f55d4 !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: #2f55d4 !important;
}

#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  border-color: #3c4858;
}

#topnav.nav-sticky .buy-button .login-btn-primary {
  display: inline-block;
}

#topnav.nav-sticky .buy-button .login-btn-light {
  display: none;
}

#topnav.nav-sticky .logo .l-dark {
  display: inline-block;
}

#topnav.nav-sticky .logo .l-light {
  display: none;
}

.logo {
  letter-spacing: 1px;
  padding: 0 0 6px;
  font-size: 24px;
  font-weight: 700;
  line-height: 68px;
}

@media (min-width: 992px) {
  #topnav .navigation-menu {
    justify-content: center;
    display: flex;
  }

  #topnav .navigation-menu > .has-submenu > .submenu > .has-submenu > .submenu:before {
    border-color: rgba(0, 0, 0, 0) #fff #fff rgba(0, 0, 0, 0);
    margin-left: -35px;
    top: 20px;
    box-shadow: 1px 1px rgba(60, 72, 88, .15);
  }

  #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
    top: 33px !important;
  }

  #topnav .navigation-menu > .has-submenu.active .menu-arrow {
    top: 30px;
  }

  #topnav .navigation-menu > li .submenu {
    z-index: 1000;
    min-width: 180px;
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    border-radius: 6px;
    margin-top: 10px;
    padding: 15px 0;
    list-style: none;
    transition: all .2s;
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 0 3px rgba(60, 72, 88, .15);
  }

  #topnav .navigation-menu > li .submenu:before {
    content: "";
    box-sizing: border-box;
    transform-origin: 0 0;
    border: 7px solid rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #fff #fff;
    position: absolute;
    top: 2px;
    left: 45px;
    transform: rotate(135deg);
    box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, .15);
  }

  #topnav .navigation-menu > li .submenu li {
    position: relative;
  }

  #topnav .navigation-menu > li .submenu li a {
    clear: both;
    white-space: nowrap;
    text-transform: uppercase;
    letter-spacing: .04em;
    padding: 10px 20px;
    font-size: 11px;
    font-weight: 700;
    transition: all .3s;
    display: block;
    color: #3c4858 !important;
  }

  #topnav .navigation-menu > li .submenu li a:hover {
    color: #2f55d4 !important;
  }

  #topnav .navigation-menu > li .submenu li ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }

  #topnav .navigation-menu > li .submenu.megamenu {
    white-space: nowrap;
    width: auto;
  }

  #topnav .navigation-menu > li .submenu.megamenu > li {
    min-width: 180px;
    vertical-align: top;
    display: inline-block;
    overflow: hidden;
  }

  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    margin-top: -1px;
    margin-left: 10px;
    top: 0;
    left: 100%;
  }

  #topnav .navigation-menu > li .submenu > li .submenu {
    margin-top: -1px;
    margin-left: 10px;
    top: 0;
    left: 106%;
  }

  #topnav .navigation-menu > li > a {
    min-height: 62px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  #topnav .navigation-menu > li:hover > .menu-arrow {
    border-color: #2f55d4;
  }

  #topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
    color: #2f55d4 !important;
  }

  #topnav .navigation-menu > li.last-elements .submenu {
    left: auto;
    right: 0;
  }

  #topnav .navigation-menu > li.last-elements .submenu:before {
    left: auto;
    right: 10px;
  }

  #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
    margin-left: 0;
    margin-right: 10px;
    left: auto;
    right: 100%;
  }

  #topnav .navigation-menu.nav-light > li > a {
    color: rgba(255, 255, 255, .5);
  }

  #topnav .navigation-menu.nav-light > li.active > a {
    color: #fff !important;
  }

  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    border-color: #fff !important;
  }

  #topnav .navigation-menu.nav-light > li:hover > a {
    color: #fff !important;
  }

  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: rgba(255, 255, 255, .5);
  }

  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    border-color: #fff !important;
  }

  #topnav .navigation-menu.nav-right {
    justify-content: flex-end !important;
  }

  #topnav .navigation-menu.nav-left {
    justify-content: flex-start !important;
  }

  #topnav .navigation-menu.nav-left > li.last-elements .submenu {
    left: 0 !important;
    right: auto !important;
  }

  #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
    left: 45px !important;
    right: auto !important;
  }

  #topnav .buy-button {
    margin-left: 15px;
    padding-left: 15px;
  }

  #topnav .navbar-toggle {
    display: none;
  }

  #topnav #navigation {
    display: block !important;
  }

  #topnav.scroll {
    top: 0;
  }

  #topnav.scroll .navigation-menu > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  #topnav {
    min-height: 74px;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(60, 72, 88, .15);
  }

  #topnav .logo .l-dark {
    display: inline-block !important;
  }

  #topnav .logo .l-light {
    display: none !important;
  }

  #topnav .container {
    width: auto;
  }

  #topnav #navigation {
    max-height: 400px;
  }

  #topnav .navigation-menu, #topnav .navigation-menu > li {
    float: none;
  }

  #topnav .navigation-menu > li .submenu {
    margin: 0;
    padding-left: 20px;
    list-style: none;
    display: none;
  }

  #topnav .navigation-menu > li .submenu li a {
    text-transform: uppercase;
    letter-spacing: .04em;
    padding: 7px 15px;
    font-size: 11px;
    font-weight: 700;
    transition: all .3s;
    display: block;
    position: relative;
    color: #3c4858 !important;
  }

  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }

  #topnav .navigation-menu > li .submenu .submenu {
    list-style: none;
    display: none;
  }

  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }

  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    padding-left: 0;
    list-style: none;
  }

  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #8492a6;
    padding: 10px 15px;
    font-size: 12px;
    display: block;
    position: relative;
  }

  #topnav .navigation-menu > li > a {
    color: #3c4858;
    padding: 10px 20px;
  }

  #topnav .navigation-menu > li > a:after {
    position: absolute;
    right: 15px;
  }

  #topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li .submenu li a:hover, #topnav .navigation-menu > li.has-submenu.open > a {
    color: #2f55d4;
  }

  #topnav .menu-extras .menu-item {
    border-color: #8492a6;
  }

  #topnav .navbar-header {
    float: left;
  }

  #topnav .buy-button .login-btn-primary {
    display: inline-block !important;
  }

  #topnav .buy-button .login-btn-light {
    display: none;
  }

  #topnav .has-submenu .submenu .submenu-arrow {
    position: absolute;
    top: 12px;
    right: 20px;
    transform: rotate(45deg);
  }

  #topnav .has-submenu.active a {
    color: #2f55d4;
  }

  #navigation {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-top: 1px solid #f1f3f9;
    border-bottom: 1px solid #f1f3f9;
    padding-bottom: 0;
    display: none;
    position: absolute;
    top: 74px;
    left: 0;
    overflow: auto;
  }

  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}

@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    top: 16px;
    right: 16px;
  }

  #topnav .buy-button {
    padding: 3px 0;
  }
}

@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }

  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-toggle {
    display: block;
  }
}

@media (max-width: 375px) {
  #topnav .buy-button {
    display: none;
  }

  #topnav .buy-menu-btn {
    margin: 0 10px;
    padding: 10px 20px;
    display: block !important;
  }

  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    transform: translate3d(0, -54px, 0) !important;
  }

  #topnav .navbar-toggle .lines {
    margin-right: 0 !important;
  }
}

.bg-home {
  height: 100vh;
  background-position: center;
  background-size: cover;
  align-self: center;
  position: relative;
}

.bg-half-260 {
  background-position: center;
  background-size: cover;
  align-self: center;
  padding: 260px 0;
  position: relative;
}

.bg-half-170 {
  background-position: center;
  background-size: cover;
  align-self: center;
  padding: 170px 0;
  position: relative;
}

.bg-half-150 {
  background-position: center;
  background-size: cover;
  align-self: center;
  padding: 150px 0;
  position: relative;
}

.bg-half-100 {
  background-position: center;
  background-size: cover;
  align-self: center;
  padding: 100px 0;
  position: relative;
}

.bg-half-50 {
  background-position: center;
  background-size: cover;
  align-self: center;
  padding: 50px 0;
  position: relative;
}

.bg-half {
  background-position: center;
  background-size: cover;
  align-self: center;
  padding: 184px 0 90px;
  position: relative;
}

.bg-auth-home {
  background-position: center;
  background-size: cover;
  align-self: center;
  padding: 142px 0;
  position: relative;
}

.gradient {
  opacity: .9;
  background: linear-gradient(45deg, #2f55d4, #2f55d4) !important;
}

.bg-circle-gradiant {
  background: radial-gradient(circle, rgba(47, 85, 212, .1) 0%, rgba(47, 85, 212, .1) 33.333%, rgba(47, 85, 212, .3) 33.333%, rgba(47, 85, 212, .3) 66.666%, rgba(47, 85, 212, .5) 66.666%, rgba(47, 85, 212, .5) 99.999%);
}

.home-dashboard img {
  z-index: 1;
  position: relative;
  top: 60px;
}

@media (min-width: 768px) {
  .home-dashboard.onepage-hero {
    overflow: hidden;
  }
}

.classic-saas-image .bg-saas-shape:after {
  width: 75rem;
  height: 55rem;
  opacity: .9;
  border-radius: 20rem;
  bottom: 3rem;
  left: 0;
  transform: rotate(130deg);
}

@media (max-width: 767px) {
  .classic-saas-image .bg-saas-shape:after {
    width: 30rem;
    height: 30rem;
    border-radius: 10rem;
    bottom: -5rem;
    left: 10rem;
  }
}

@media (max-width: 768px) {
  .classic-saas-image .bg-saas-shape img {
    max-width: 550px;
  }
}

@media (max-width: 767px) {
  .classic-saas-image .bg-saas-shape img {
    max-width: 100%;
    height: auto;
  }
}

.studio-home {
  padding: 7%;
}

.agency-wrapper:before {
  content: "";
  width: 90rem;
  height: 35rem;
  z-index: -1;
  background-color: #f8f9fc;
  border-radius: 17rem;
  position: absolute;
  bottom: 22rem;
  right: 54rem;
  transform: rotate(45deg);
}

@media (max-width: 768px) {
  .agency-wrapper:before {
    right: 12rem;
  }
}

@media (max-width: 767px) {
  .agency-wrapper:before {
    right: 0;
  }
}

.bg-marketing {
  background-position: center;
  background-size: cover;
  align-self: center;
  padding: 76px 0 120px;
  position: relative;
}

.home-slider .carousel-control-next, .home-slider .carousel-control-prev {
  width: 5%;
}

.home-slider .carousel-item, .home-slider .carousel {
  height: 100vh;
  width: 100%;
}

.home-slider .carousel-item {
  background-position: center;
  background-size: cover;
}

.home-slider .carousel-item.active {
  display: flex;
}

.main-slider .slides .bg-slider {
  height: 100vh;
  background-position: center;
  background-size: cover;
  align-self: center;
  position: relative;
}

.main-slider .slides .bg-slider.shopping {
  height: 75vh;
}

.flex-control-paging li a {
  box-shadow: none;
  background: #fff;
}

.flex-control-paging li a.flex-active {
  cursor: default;
  width: 15px;
  height: 15px;
  box-shadow: none;
  background: #2f55d4;
  font-size: 17px;
}

.flex-control-nav {
  display: none;
}

.bg-animation-left:after {
  content: "";
  width: 70%;
  height: 100%;
  -webkit-clip-path: polygon(0 0, 90% 0, 70% 100%, 0% 100%);
  clip-path: polygon(0 0, 90% 0, 70% 100%, 0% 100%);
  background: rgba(255, 255, 255, .85);
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  .bg-animation-left:after {
    width: 100%;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

.bg-animation-left.dark-left:after {
  background: rgba(60, 72, 88, .6);
}

.bg-animation-left.crypto-home:after {
  width: 80%;
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
  background: #2f55d4;
}

.bg-animation-left.task-management-home:after {
  width: 100%;
  -webkit-clip-path: circle(65% at 40% 25%);
  clip-path: circle(65% at 40% 25%);
  background: #2f55d4 url("bg.3307f785.png");
}

.bg-invoice {
  background-position: center;
  background-size: cover;
  align-self: center;
  padding: 100px 0;
  position: relative;
}

.classic-app-image .bg-app-shape:after {
  width: 55rem;
  height: 35rem;
  opacity: .9;
  border-radius: 20rem;
  bottom: 3rem;
  left: -8rem;
  transform: rotate(120deg);
}

.classic-app-image .app-images {
  position: absolute;
  bottom: 60px;
  right: -20px;
}

.classic-app-image .bg-app-shape:after, .classic-saas-image .bg-saas-shape:after {
  content: " ";
  z-index: -1;
  background-color: #2f55d4;
  position: absolute;
  box-shadow: 0 0 40px rgba(47, 85, 212, .5);
}

.shape-before .shape-img {
  z-index: -1;
  height: auto;
  position: absolute;
  top: -50px;
  left: -35px;
  overflow: hidden;
}

.swiper-slider-hero .swiper-container, .swiper-slider-hero .swiper-slide .slide-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.swiper-slider-hero .swiper-container .swiper-button-prev, .swiper-slider-hero .swiper-container .swiper-button-next {
  width: 35px;
  height: 35px;
  background: none;
  margin-top: -30px;
  line-height: 35px;
}

.swiper-slider-hero .swiper-container .swiper-button-prev:before, .swiper-slider-hero .swiper-container .swiper-button-next:before {
  color: #fff;
  font-family: Material Design Icons;
  font-weight: 900;
}

.swiper-slider-hero .swiper-container .swiper-button-prev:hover, .swiper-slider-hero .swiper-container .swiper-button-next:hover {
  background: #2f55d4;
  border-color: #2f55d4 !important;
}

.swiper-slider-hero .swiper-container .swiper-button-prev {
  left: 10px;
}

.swiper-slider-hero .swiper-container .swiper-button-prev:before {
  content: "󰅁";
}

.swiper-slider-hero .swiper-container .swiper-button-next {
  right: 10px;
}

.swiper-slider-hero .swiper-container .swiper-button-next:before {
  content: "󰅂";
}

.swiper-slider-hero .swiper-pagination-bullet {
  color: #f8f9fa;
  background: none;
}

.swiper-slider-hero .swiper-pagination-bullet-active {
  color: #fff;
}

.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets, .swiper-slider-hero .swiper-pagination-custom, .swiper-slider-hero .swiper-pagination-fraction {
  bottom: 45px;
}

.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 13px;
}

@media (min-width: 769px) {
  .bg-studio:after {
    content: "";
    width: 50%;
    height: 100%;
    z-index: 2;
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 768px) {
  .bg-half {
    padding: 169px 0 50px;
  }
}

@media (max-width: 767px) {
  .bg-home, .bg-half-170, .bg-half-260, .main-slider .slides .bg-slider, .bg-marketing, .swiper-slider-hero .swiper-container .swiper-slide {
    height: auto;
    padding: 150px 0;
  }
}

.features .icon {
  background: rgba(47, 85, 212, .1);
}

.features .image:before {
  content: "";
  width: 64px;
  height: 64px;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(47, 85, 212, .1));
  border-radius: 6px;
  position: absolute;
  bottom: 5px;
  left: 5px;
  transform: rotate(33.75deg);
}

.features.feature-clean .icons i {
  height: 65px;
  width: 65px;
  background-color: rgba(47, 85, 212, .1);
  line-height: 65px;
}

.features.feature-clean .title:hover {
  color: #2f55d4 !important;
}

.features .ride-image {
  transform: rotate(-45deg);
}

.features .ride-image img {
  border-radius: 100% 100% 100% 0;
}

.features:hover .image:before {
  background: rgba(47, 85, 212, .1);
  animation: spinner-border 5s linear infinite !important;
}

.features.fea-primary, .features.fea-primary .content .title {
  transition: all .5s;
}

.features.fea-primary .content .title:hover {
  color: #2f55d4 !important;
}

.features.fea-primary .big-icon {
  opacity: .015;
  font-size: 180px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.features.fea-primary:hover {
  box-shadow: 0 10px 25px rgba(60, 72, 88, .15);
  color: #fff !important;
  background-color: #2f55d4 !important;
}

.features.fea-primary:hover .icon2, .features.fea-primary:hover .para {
  color: rgba(255, 255, 255, .5) !important;
}

.features.fea-primary:hover .content, .features.fea-primary:hover .icon2 {
  z-index: 2;
}

.features.fea-primary:hover .big-icon {
  opacity: .05;
}

.features .title, .key-feature .title {
  font-size: 18px !important;
}

.features .icon, .key-feature .icon {
  height: 45px;
  width: 45px;
  line-height: 45px;
}

.key-feature {
  transition: all .3s;
}

.key-feature .icon {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(47, 85, 212, .15));
}

.key-feature:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 13px rgba(60, 72, 88, .2) !important;
}

.work-process {
  transition: all .5s;
}

.work-process .step, .work-process .step-icon {
  opacity: .06;
}

.work-process a {
  font-size: 15px;
}

.work-process.process-arrow:after {
  content: "";
  width: 60%;
  height: 40px;
  z-index: 1;
  background: url("process.94341ffe.png") center no-repeat;
  position: absolute;
  top: 80px;
  left: 80%;
}

@media (max-width: 767px) {
  .work-process.process-arrow:after {
    display: none;
  }
}

.work-process.process-arrow:hover {
  transform: none !important;
}

.work-process:hover {
  transform: translateY(-10px);
}

.work-process.d-none-arrow:hover {
  transform: none !important;
}

.course-feature {
  transition: all .5s;
}

.course-feature .full-img {
  opacity: 0;
  z-index: -2;
  margin: 0 auto;
  font-size: 250px;
  transition: all .5s;
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
  transform: translateY(50%);
}

.course-feature:hover {
  transform: translateY(-10px);
}

.course-feature:hover .full-img {
  opacity: .05;
}

.features-absolute {
  z-index: 2;
  margin: -200px 0 0;
  transition: all .5s;
  position: relative;
}

@media (max-width: 768px) {
  .features-absolute {
    margin: -140px 0 0;
  }
}

.features-absolute.blog-search {
  margin: -120px 0 0;
}

@media (max-width: 768px) {
  .features-absolute.blog-search {
    margin: -90px 0 0;
  }
}

.categories {
  transition: all .5s;
}

.categories:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 3px rgba(60, 72, 88, .15);
}

.categories a:hover {
  color: #2f55d4 !important;
}

.app-feature-shape-right:after, .app-feature-shape-left:after {
  width: 30rem;
  height: 40rem;
  border-radius: 10rem;
  bottom: 0;
}

.app-feature-shape-right:after {
  left: 10rem;
  transform: rotate(150deg);
}

@media (max-width: 768px) {
  .app-feature-shape-right:after {
    left: 0;
  }
}

.app-feature-shape-left:after {
  right: 10rem;
  transform: rotate(210deg);
}

@media (max-width: 768px) {
  .app-feature-shape-left:after {
    right: 0;
  }
}

.app-subscribe {
  position: absolute;
  top: -200px;
}

@media (max-width: 767px) {
  .app-subscribe {
    position: relative;
    top: 0;
  }
}

.saas-feature-shape-left:after, .saas-feature-shape-right:after {
  width: 35rem;
  height: 35rem;
  border-radius: 15rem;
  bottom: -3rem;
  transform: rotate(145deg);
}

@media (max-width: 767px) {
  .saas-feature-shape-left:after, .saas-feature-shape-right:after {
    width: 25rem;
    height: 25rem;
    border-radius: 10rem;
    bottom: -1rem;
  }
}

.saas-feature-shape-left:after {
  left: -10rem;
}

.saas-feature-shape-right:after {
  right: -10rem;
}

.app-feature-shape-right:after, .app-feature-shape-left:after, .saas-feature-shape-left:after, .saas-feature-shape-right:after, .social-feature-left:after, .social-feature-right:after {
  content: "";
  z-index: -1;
  background: rgba(47, 85, 212, .1);
  position: absolute;
  box-shadow: 0 0 40px rgba(47, 85, 212, .1);
}

.social-feature-left:after, .social-feature-right:after {
  width: 450px;
  height: 520px;
  border-radius: 30px;
  bottom: -75px;
}

@media (max-width: 768px) {
  .social-feature-left:after, .social-feature-right:after {
    width: 300px;
    height: 400px;
    bottom: -65px;
  }
}

@media (max-width: 575px) {
  .social-feature-left:after, .social-feature-right:after {
    bottom: -35px;
  }
}

.social-feature-left:after {
  right: -200px;
}

@media (max-width: 575px) {
  .social-feature-left:after {
    right: 60px;
  }
}

.social-feature-right:after {
  left: -200px;
}

@media (max-width: 575px) {
  .social-feature-right:after {
    left: 60px;
  }
}

.explore-feature {
  transition: all .5s;
}

.explore-feature .icon {
  height: 80px;
  width: 80px;
  line-height: 80px;
  transition: all .5s;
}

.explore-feature:hover {
  box-shadow: 0 10px 25px rgba(60, 72, 88, .15) !important;
}

.explore-feature:hover .icon, .explore-feature:hover .title {
  color: #2f55d4 !important;
}

.construction-img {
  position: absolute;
  bottom: 0;
  right: 20px;
}

.owl-theme .owl-dots .owl-dot span {
  background: #2f55d4 !important;
}

.customer-testi {
  cursor: e-resize;
}

.customer-testi .content:before {
  content: "";
  box-sizing: border-box;
  transform-origin: 0 0;
  border: 8px solid #fff;
  border-color: rgba(0, 0, 0, 0) #fff #fff rgba(0, 0, 0, 0);
  margin-left: 13px;
  position: absolute;
  top: 30px;
  left: 0;
  transform: rotate(135deg);
  box-shadow: 2px 2px 2px -1px rgba(60, 72, 88, .15);
}

.owl-theme .owl-dots .owl-dot span {
  border-radius: 3px;
  transition: all .5s;
  background: rgba(47, 85, 212, .5) !important;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots.clickable .owl-dot:hover span {
  transform: rotate(45deg);
  background: #2f55d4 !important;
}

.owl-carousel .owl-item img {
  width: auto;
  display: block;
}

.slick-slider {
  cursor: pointer;
  overflow-x: hidden !important;
}

.pricing-rates {
  z-index: 1;
  transition: all .5s;
  position: relative;
  border-bottom: 3px solid #adb5bd !important;
}

.pricing-rates .price {
  font-weight: 400;
}

.pricing-rates.business-rate:hover {
  z-index: 2;
  transform: scale(1.03);
  background-color: #fff !important;
  border-color: #2f55d4 !important;
  box-shadow: 0 5px 13px rgba(60, 72, 88, .2) !important;
}

.pricing-rates.business-rate:hover .title {
  color: #2f55d4;
}

.pricing-rates.starter-plan {
  z-index: 2;
  transform: scale(1.05);
  border-color: #2f55d4 !important;
}

@media (max-width: 767px) {
  .pricing-rates.starter-plan {
    transform: scale(1);
  }
}

.pricing-rates.best-plan:hover {
  transform: translateY(-10px);
}

.ribbon.ribbon-primary span {
  background-color: #2f55d4 !important;
}

.ribbon.ribbon-primary span:before {
  border-top-color: #2443ac;
  border-left-color: #2443ac;
}

.ribbon.ribbon-primary span:after {
  border-top-color: #2443ac;
  border-right-color: #2443ac;
}

.ribbon.ribbon-secondary span {
  background-color: #5a6d90 !important;
}

.ribbon.ribbon-secondary span:before {
  border-top-color: #465571;
  border-left-color: #465571;
}

.ribbon.ribbon-secondary span:after {
  border-top-color: #465571;
  border-right-color: #465571;
}

.ribbon.ribbon-success span {
  background-color: #2eca8b !important;
}

.ribbon.ribbon-success span:before {
  border-top-color: #25a06e;
  border-left-color: #25a06e;
}

.ribbon.ribbon-success span:after {
  border-top-color: #25a06e;
  border-right-color: #25a06e;
}

.ribbon.ribbon-warning span {
  background-color: #f17425 !important;
}

.ribbon.ribbon-warning span:before {
  border-top-color: #d55b0e;
  border-left-color: #d55b0e;
}

.ribbon.ribbon-warning span:after {
  border-top-color: #d55b0e;
  border-right-color: #d55b0e;
}

.ribbon.ribbon-info span {
  background-color: #17a2b8 !important;
}

.ribbon.ribbon-info span:before {
  border-top-color: #117a8b;
  border-left-color: #117a8b;
}

.ribbon.ribbon-info span:after {
  border-top-color: #117a8b;
  border-right-color: #117a8b;
}

.ribbon.ribbon-danger span {
  background-color: #e43f52 !important;
}

.ribbon.ribbon-danger span:before {
  border-top-color: #d21e32;
  border-left-color: #d21e32;
}

.ribbon.ribbon-danger span:after {
  border-top-color: #d21e32;
  border-right-color: #d21e32;
}

.ribbon.ribbon-dark span {
  background-color: #3c4858 !important;
}

.ribbon.ribbon-dark span:before {
  border-top-color: #272f3a;
  border-left-color: #272f3a;
}

.ribbon.ribbon-dark span:after {
  border-top-color: #272f3a;
  border-right-color: #272f3a;
}

.ribbon.ribbon-muted span {
  background-color: #8492a6 !important;
}

.ribbon.ribbon-muted span:before {
  border-top-color: #68788f;
  border-left-color: #68788f;
}

.ribbon.ribbon-muted span:after {
  border-top-color: #68788f;
  border-right-color: #68788f;
}

.ribbon.ribbon-light span {
  background-color: #f8f9fc !important;
}

.ribbon.ribbon-light span:before {
  border-top-color: #d4daed;
  border-left-color: #d4daed;
}

.ribbon.ribbon-light span:after {
  border-top-color: #d4daed;
  border-right-color: #d4daed;
}

.ribbon.ribbon-blue span {
  background-color: #2f55d4 !important;
}

.ribbon.ribbon-blue span:before {
  border-top-color: #2443ac;
  border-left-color: #2443ac;
}

.ribbon.ribbon-blue span:after {
  border-top-color: #2443ac;
  border-right-color: #2443ac;
}

.ribbon.ribbon-footer span {
  background-color: #202942 !important;
}

.ribbon.ribbon-footer span:before {
  border-top-color: #0f131f;
  border-left-color: #0f131f;
}

.ribbon.ribbon-footer span:after {
  border-top-color: #0f131f;
  border-right-color: #0f131f;
}

.ribbon {
  width: 75px;
  height: 75px;
  z-index: 2;
  position: absolute;
  top: -5px;
}

.ribbon.ribbon-right {
  right: -5px;
}

.ribbon.ribbon-right span {
  right: -21px;
  transform: rotate(45deg);
}

.ribbon.ribbon-left {
  left: -5px;
}

.ribbon.ribbon-left span {
  left: -21px;
  transform: rotate(-45deg);
}

.ribbon span {
  width: 100px;
  color: #fff;
  position: absolute;
  top: 19px;
}

.ribbon span:before, .ribbon span:after {
  content: "";
  z-index: -1;
  border: 3px solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 100%;
}

.ribbon span:before {
  left: 0;
}

.ribbon span:after {
  right: 0;
}

.invoice-top .logo-invoice {
  letter-spacing: 1px;
  font-size: 27px;
  font-weight: 700;
}

.watch-video a .play-icon-circle {
  height: 36px;
  width: 36px;
  background-color: #2f55d4;
  line-height: 36px;
}

.watch-video a .play-icon-circle.play-iconbar {
  font-size: 16px;
}

.watch-video a .play-icon-circle.play {
  top: 77%;
}

.bg-cta {
  padding: 150px 0;
}

.play-btn {
  height: 78px;
  width: 78px;
  color: #fff;
  border-radius: 50%;
  font-size: 30px;
  line-height: 76px;
  display: inline-block;
}

.play-icon {
  text-align: center;
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
  transform: translateY(50%);
}

.play-icon i {
  height: 75px;
  -webkit-text-stroke: 2px #2f55d4;
  -webkit-text-fill-color: transparent;
  width: 75px;
  font-size: 25px;
  line-height: 75px;
  display: inline-block;
}

.play-icon i:hover {
  -webkit-text-stroke: 2px #2f55d4;
  -webkit-text-fill-color: #2f55d4;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1110px;
}

.blog {
  transition: all .3s;
}

.blog .content h4 {
  line-height: 1.2;
}

.blog .content .title {
  transition: all .5s;
}

.blog .content .title:hover {
  color: #2f55d4 !important;
}

.blog .content .post-meta .like, .blog .content .post-meta .comments, .blog .content .post-meta .readmore {
  font-size: 15px;
  transition: all .5s;
}

.blog .content .post-meta .like:hover {
  color: #e43f52 !important;
}

.blog .content .post-meta .comments:hover {
  color: #2eca8b !important;
}

.blog .content .post-meta .readmore:hover {
  color: #2f55d4 !important;
}

.blog .author, .blog .teacher, .blog .course-fee {
  z-index: 1;
  opacity: 0;
  transition: all .5s;
  position: absolute;
}

.blog .author {
  top: 5%;
  left: 5%;
}

.blog .teacher {
  bottom: 6%;
  left: 5%;
}

.blog .course-fee {
  width: 50px;
  height: 50px;
  bottom: -5%;
  right: 5%;
}

.blog .course-fee .fee {
  line-height: 50px;
}

.blog.blog-detail:hover {
  transform: translateY(0) !important;
}

.blog:hover {
  transform: translateY(-10px);
}

.blog:hover .overlay {
  opacity: .4;
}

.blog:hover .author, .blog:hover .teacher, .blog:hover .course-fee {
  opacity: 1;
}

.blog .blog-list-meta {
  position: absolute;
  bottom: 24px;
}

.sidebar .widget .widget-search form {
  position: relative;
}

.sidebar .widget .widget-search input[type="text"], .sidebar .widget .searchform input[type="text"] {
  box-shadow: none;
  height: 45px;
  width: 100%;
  padding: 12px 45px 12px 15px;
  font-size: 14px;
  display: block;
  outline: none !important;
}

.sidebar .widget .widget-search input[type="submit"], .sidebar .widget .searchform input[type="submit"] {
  opacity: 0;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  right: 10px;
}

.sidebar .widget .widget-search .searchform:after {
  content: "󰍉";
  pointer-events: none;
  font-family: Material Design Icons;
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  top: 15px;
  right: 16px;
}

.sidebar .widget .blog-categories li {
  padding-bottom: 10px;
}

.sidebar .widget .blog-categories li:last-child {
  padding-bottom: 0;
}

.sidebar .widget .blog-categories li a, .sidebar .widget .blog-categories li span {
  font-size: 15px;
}

.sidebar .widget .blog-categories li a {
  color: #3c4858;
  transition: all .5s;
}

.sidebar .widget .blog-categories li a:hover {
  color: #2f55d4;
}

.sidebar .widget .post-recent {
  padding-bottom: 15px;
}

.sidebar .widget .post-recent:last-child {
  padding-bottom: 0;
}

.sidebar .widget .post-recent .post-recent-thumb {
  width: 25%;
}

.sidebar .widget .post-recent .post-recent-content {
  width: 75%;
  padding-left: 10px;
}

.sidebar .widget .post-recent .post-recent-content a {
  color: #3c4858;
  font-size: 15px;
  transition: all .5s;
  display: block;
}

.sidebar .widget .post-recent .post-recent-content a:hover {
  color: #2f55d4;
}

.sidebar .widget .post-recent .post-recent-content span {
  font-size: 13px;
}

.sidebar .widget .tagcloud > a {
  color: #3c4858;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #e9ecef;
  margin: 5px 10px 5px 0;
  padding: 8px 12px 7px;
  font-size: 9px;
  font-weight: 600;
  transition: all .5s;
  display: inline-block;
}

.sidebar .widget .tagcloud > a:hover {
  color: #fff;
  background: #2f55d4;
}

.media-list .media .media-heading:hover {
  color: #2f55d4 !important;
}

.media-list .sub-comment {
  border-left: 2px dashed #dee2e6;
}

.blog .overlay, .job-box .job-overlay {
  opacity: 0;
  transition: all .5s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.job-box {
  transition: all .5s;
}

.job-box .job-overlay {
  opacity: .6;
}

.job-box .head {
  position: absolute;
  top: 15px;
  right: 15px;
}

.job-box .position {
  position: absolute;
  top: 15px;
  left: 15px;
}

.job-box .position a {
  transition: all .5s;
}

.job-box .position a:hover {
  color: #2f55d4 !important;
}

.job-box .firm-logo {
  height: 60px;
  width: 60px;
  margin: 0 auto;
  line-height: 60px;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
}

.job-box .company-name {
  transition: all .5s;
}

.job-box .company-name:hover {
  color: #2f55d4 !important;
}

.job-box:hover {
  transform: translateY(-5px);
}

.candidate-list .like {
  -webkit-text-stroke: 1px #8492a6;
  -webkit-text-fill-color: transparent;
}

.candidate-list .like:hover, .candidate-list .like:focus {
  -webkit-text-stroke: #e43f52;
  -webkit-text-fill-color: #e43f52;
}

.candidate-list, .company-list {
  transition: all .5s;
}

.candidate-list .name:hover, .company-list .name:hover {
  color: #2f55d4 !important;
}

.candidate-list:hover, .company-list:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 13px rgba(60, 72, 88, .2) !important;
}

@media (min-width: 1024px) {
  .job-profile {
    top: -378px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .job-profile {
    top: -338px;
  }
}

@media (max-width: 767px) {
  .company-logo {
    float: none !important;
    text-align: left !important;
  }
}

.work-container.work-classic .work-image {
  transition: all .5s;
}

.work-container.work-classic .work-image:hover {
  box-shadow: 0 0 3px rgba(60, 72, 88, .15);
}

.work-container.work-classic .content .title, .work-container.work-grid .content .title, .work-container.work-modern .content .title {
  font-size: 18px;
  transition: all .5s;
}

.work-container.work-classic .content .title:hover, .work-container.work-grid .content .title:hover, .work-container.work-modern .content .title:hover {
  color: #2f55d4 !important;
}

.work-container.work-classic .content .tag, .work-container.work-grid .content .tag, .work-container.work-modern .content .tag {
  font-size: 15px !important;
}

.work-container.work-modern img, .work-container.work-modern .overlay-work, .work-container.work-modern .content, .work-container.work-modern .client, .work-container.work-modern .read_more, .work-container.work-modern .icons, .work-container.work-modern .icons .work-icon {
  transition: all .5s;
}

.work-container.work-modern .overlay-work, .work-container.work-modern .content, .work-container.work-modern .client, .work-container.work-modern .read_more, .work-container.work-modern .icons {
  position: absolute;
}

.work-container.work-modern .overlay-work {
  opacity: 0;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.work-container.work-modern .content {
  z-index: 1;
  bottom: 5%;
  left: 5%;
}

.work-container.work-modern .content, .work-container.work-modern .client {
  opacity: 0;
}

.work-container.work-modern .client, .work-container.work-modern .read_more {
  z-index: 1;
  top: 5%;
  right: 5%;
}

.work-container.work-modern .read_more {
  height: 30px;
  width: 30px;
  line-height: 30px;
}

.work-container.work-modern .icons {
  z-index: 2;
  opacity: 0;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.work-container.work-modern .icons .work-icon {
  height: 45px;
  width: 45px;
  line-height: 45px;
}

.work-container.work-modern .icons .work-icon:hover {
  color: #fff !important;
  background: #2f55d4 !important;
}

.work-container.work-modern:hover img {
  transform: scale(1.1)rotate(3deg);
}

.work-container.work-modern:hover .overlay-work {
  opacity: .65;
}

.work-container.work-modern:hover .icons, .work-container.work-modern:hover .personal-port, .work-container.work-modern:hover .content, .work-container.work-modern:hover .client {
  opacity: 1;
}

.work-container.work-grid, .work-container.work-grid img, .work-container.work-grid .content, .work-container.work-grid .content .title {
  transition: all .5s;
}

.work-container.work-grid .content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  transform: translateY(150px);
}

.work-container.work-grid:hover {
  box-shadow: 0 0 3px rgba(60, 72, 88, .15);
}

.work-container.work-grid:hover img {
  transform: translateY(-40px);
}

.work-container.work-grid:hover .content {
  transform: translateY(0);
}

.work-details ul li b {
  max-width: 90px;
  width: 100%;
  display: inline-block;
}

.courses-desc img {
  transition: all .5s;
}

.courses-desc:hover img {
  transform: scale(1.1)rotate(3deg);
}

.courses-desc .overlay-work, .popular-tour .overlay-work {
  opacity: 0;
  transition: all .5s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.courses-desc:hover .overlay-work, .popular-tour:hover .overlay-work {
  opacity: .6;
}

.event-schedule {
  transition: all .5s;
}

.event-schedule .date .day {
  width: 50px;
  height: 50px;
  background: rgba(47, 85, 212, .1);
  border: 2px solid #fff;
  border-radius: 50px;
  font-size: 18px;
  line-height: 48px;
  box-shadow: 0 0 2px .25px rgba(47, 85, 212, .5);
}

.event-schedule .date .month {
  font-size: 13px;
}

.event-schedule .content .title {
  font-size: 20px;
  transition: all .5s;
}

.event-schedule .content .title:hover {
  color: #2f55d4 !important;
}

.event-schedule .content .location-time {
  font-size: 14px;
}

.event-schedule:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 3px rgba(60, 72, 88, .15);
  background: #f8f9fc !important;
  border-color: #2f55d4 !important;
}

.courses-desc, .courses-desc .content .title {
  transition: all .5s;
}

.courses-desc .content .title:hover {
  color: #2f55d4 !important;
}

.courses-desc .collection, .courses-desc .preview {
  opacity: 0;
  z-index: 1;
  position: absolute;
}

.courses-desc .collection {
  top: 15px;
  left: 15px;
}

.courses-desc .collection i {
  -webkit-text-stroke: 2px #e43f52;
  -webkit-text-fill-color: transparent;
}

.courses-desc .collection i:active {
  -webkit-text-stroke: 0px #e43f52;
  -webkit-text-fill-color: #e43f52;
}

.courses-desc .preview {
  bottom: 15px;
  right: 15px;
}

.courses-desc:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 3px rgba(60, 72, 88, .15);
}

.courses-desc:hover .collection, .courses-desc:hover .preview {
  opacity: 1;
}

.personal-port {
  opacity: 0;
  transition: all .5s;
}

.container-filter li a {
  letter-spacing: .8px;
  cursor: pointer;
  margin: 5px 3px;
  padding: 0 15px;
  font-size: 13px;
  font-weight: 700;
  line-height: 34px;
  transition: all .5s;
}

.container-filter li a.active, .container-filter li a:hover {
  color: #2f55d4 !important;
  border-color: #2f55d4 !important;
}

.spacing {
  padding: 7px;
}

.shop-list .shop-image .overlay-work, .shop-list .shop-image .shop-icons {
  opacity: 0;
  transition: all .5s;
  position: absolute;
}

.shop-list .shop-image .overlay-work {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.shop-list .shop-image .overlay-work .out-stock {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.shop-list .shop-image .shop-icons {
  z-index: 2;
  top: 15px;
  right: 15px;
}

.shop-list .shop-image:hover .overlay-work, .shop-list .shop-image:hover .shop-icons {
  opacity: 1;
}

.shop-list .content .product-name {
  transition: all .5s;
}

.shop-list .content .product-name:hover {
  color: #2f55d4 !important;
}

.shop-list .label {
  z-index: 2;
  position: absolute;
  top: 15px;
  left: -15px;
}

.popular-tour .content {
  position: absolute;
  bottom: 3%;
  left: 5%;
}

.main-icon {
  height: 40px;
  width: 40px;
  text-align: center;
  border: 2px solid #e9ecef;
  line-height: 0;
}

.main-icon .fea {
  height: 20px;
}

@media (min-width: 768px) {
  .main-icon {
    margin: 0 auto;
  }
}

.timeline-page:after {
  content: "";
  height: 95%;
  width: 2px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #e9ecef !important;
}

.timeline-page .timeline-item .date-label-left, .timeline-page .timeline-item .duration-right {
  margin-right: 15px;
}

.timeline-page .timeline-item .date-label-left:after, .timeline-page .timeline-item .duration-right:after {
  content: "";
  width: 12px;
  height: 12px;
  z-index: 1;
  background-color: #2f55d4;
  border-radius: 4px;
  position: absolute;
  top: 12px;
  transform: rotate(45deg);
}

.timeline-page .timeline-item .date-label-left {
  float: right;
  margin-right: 15px;
}

.timeline-page .timeline-item .date-label-left:after {
  right: -37px;
}

.timeline-page .timeline-item .duration-right {
  float: left;
  margin-left: 15px;
}

.timeline-page .timeline-item .duration-right:after {
  left: -37px;
}

.timeline-page .timeline-item .event {
  transition: all .2s;
}

.timeline-page .timeline-item .event.event-description-right {
  margin-left: 15px;
}

.timeline-page .timeline-item .event.event-description-left {
  text-align: right;
  margin-right: 15px;
}

@media (max-width: 767px) {
  .timeline-page {
    margin-left: 18px;
  }

  .timeline-page:after {
    margin: 0;
  }

  .timeline-page .timeline-item .duration {
    float: left !important;
    text-align: left !important;
    margin: 0 0 30px 15px !important;
  }

  .timeline-page .timeline-item .duration:after {
    left: -21px !important;
  }

  .timeline-page .timeline-item .duration .event {
    margin-left: 15px;
    text-align: left !important;
  }

  .timeline-page .timeline-item .event-description-left {
    margin: 0 0 0 15px;
    text-align: left !important;
  }
}

.team img {
  transition: all .5s;
}

.team .name:hover {
  color: #2f55d4 !important;
}

.team .team-icon {
  opacity: 0;
  text-align: center;
  transition: all .5s;
  position: absolute;
  bottom: 50%;
  left: 0;
  right: 0;
  transform: translateY(50%);
}

.team:hover img {
  opacity: .2;
  box-shadow: 0 0 3px rgba(60, 72, 88, .15);
}

.team:hover .overlay-team {
  opacity: .6;
}

.team:hover .team-icon {
  opacity: 1;
}

.login-page {
  font-size: 15px;
}

.login-page .forgot-pass {
  z-index: 99;
  font-size: 13px;
  position: relative;
}

.cover-user .container-fluid {
  max-width: 100%;
}

@media (max-width: 768px) {
  .cover-user .container-fluid .padding-less {
    height: 400px !important;
    position: relative !important;
  }
}

.cover-user .container-fluid .padding-less.img {
  height: 100%;
  background: center / cover no-repeat;
  position: absolute;
}

.cover-user .cover-user-img {
  height: 100vh;
}

@media (min-width: 1025px) {
  .cover-user .cover-user-img {
    padding: 0 20%;
  }
}

@media (max-width: 768px) {
  .cover-user .cover-user-img {
    padding: 30px;
    height: auto !important;
    align-items: start !important;
  }
}

@media (max-width: 768px) {
  .cover-user .cover-user-content {
    text-align: left !important;
  }

  .cover-user .cover-my-30 {
    margin: 30px 0;
  }
}

.bg-profile {
  background-position: center;
  background-size: cover;
  align-self: center;
  padding: 100px 0;
  position: relative;
}

.bg-profile .public-profile {
  top: 175px;
}

.accounts, .accounts .pro-icons, .accounts .title {
  transition: all .5s;
}

.accounts:hover, .accounts.active {
  background-color: #2f55d4;
}

.accounts:hover .pro-icons, .accounts.active .pro-icons {
  color: rgba(255, 255, 255, .5) !important;
}

.accounts:hover .title, .accounts.active .title {
  color: #fff !important;
}

.coming-soon, .maintenance, .error-page {
  letter-spacing: 1px;
  font-size: 40px;
}

@media (max-width: 767px) {
  .coming-soon, .maintenance, .error-page {
    font-size: 32px;
  }
}

#countdown .count-down, #eventdown .count-down {
  color: #fff;
  text-align: center;
  height: 130px;
  width: 130px;
  border: 4px solid #dee2e6;
  border-radius: 50%;
  margin: 30px 10px;
  display: inline-block;
}

#countdown .count-down .count-number, #eventdown .count-down .count-number {
  font-size: 40px;
  line-height: 95px;
}

#countdown .count-down .count-head, #eventdown .count-down .count-head {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  display: block;
  position: relative;
  transform: translateY(-20px);
}

#eventdown .count-down {
  height: 20px;
  width: 80px;
  border: none;
  margin: 10px !important;
}

@media (max-width: 767px) {
  #eventdown .count-down {
    width: 60px;
  }
}

#eventdown .count-down .count-number {
  line-height: 80px;
}

@media (max-width: 767px) {
  #eventdown .count-down .count-number {
    font-size: 30px;
  }
}

#eventdown .count-down .count-head {
  transform: translateY(-15px);
}

@media (max-width: 767px) {
  #eventdown .count-down .count-head {
    font-size: 10px;
  }
}

#clock {
  color: #fff;
}

#clock p {
  width: 80px;
}

#clock p span {
  font-size: 40px;
}

#clock p span.indicator {
  font-size: 18px;
}

.form-group .icons {
  position: absolute;
  top: 13px;
  left: 18px;
}

.map {
  line-height: 0;
}

.map iframe {
  width: 100%;
  height: 400px;
}

.error {
  color: #e43f52;
  margin: 8px 0;
  display: none;
}

#ajaxsuccess {
  width: 100%;
  clear: both;
  margin: 8px 0;
  font-size: 16px;
  display: none;
}

.error_message {
  text-align: center;
  color: #e43f52;
  border: 2px solid #e43f52;
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 14px;
}

.contact-loader {
  display: none;
}

#success_page {
  text-align: center;
  margin-bottom: 50px;
}

#success_page h3 {
  color: #2eca8b;
  font-size: 22px;
}

.flatpickr-day.selected, .flatpickr-day.selected:hover {
  background: #2f55d4;
  border-color: #2f55d4;
}

@media (min-width: 768px) {
  .map.map-height-two iframe {
    height: 551px;
  }
}

.footer {
  color: #adb5bd;
  background: #202942;
  padding: 60px 0;
  position: relative;
}

.footer .logo-footer {
  font-size: 22px;
}

.footer .footer-head {
  letter-spacing: 1px;
  font-weight: 500;
}

.footer .foot-subscribe .form-control {
  color: #f8f9fc;
  background-color: #27314f;
  border: 1px solid #27314f;
}

.footer .foot-subscribe .form-control:focus {
  box-shadow: none;
}

.footer .foot-subscribe.foot-white .form-control {
  color: #adb5bd;
}

.footer .foot-subscribe input::-ms-input-placeholder {
  color: #adb5bd;
}

.footer .foot-subscribe input::placeholder {
  color: #adb5bd;
}

.footer .text-foot {
  color: #adb5bd;
}

.footer .footer-list {
  margin-bottom: 0;
}

.footer .footer-list li {
  margin-bottom: 10px;
}

.footer .footer-list li a {
  transition: all .5s;
}

.footer .footer-list li a:hover {
  color: #e6e8ea;
}

.footer .footer-list li:last-child {
  margin-bottom: 0;
}

.footer.footer-border, .footer.footer-bar {
  border-top: 1px solid #283353;
}

.footer.footer-bar {
  padding: 30px 0;
}

/*# sourceMappingURL=index.e90a1536.css.map */
